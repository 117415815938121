import * as businessRulesMockApi from '@/api/business-rules/mocks'
import * as businessRulesApi from '@/api/business-rules/rules'

import mutationTypes from './mutation-types'

export default {
  async getAgentRules({ commit }) {
    const rules = await businessRulesApi.getRules({
      type: businessRulesApi.RULE_TYPES.agent,
    })

    const searchAgentId = rules.find((rule) => rule.agentType === 'search')?.id
    const dedupeAgentId = rules.find((rule) => rule.agentType === 'dedupe')?.id

    commit(mutationTypes.SET_AGENT_IDS, {
      searchAgentId,
      dedupeAgentId,
    })
  },
  async getAgentRule({ commit }) {
    const rule = await businessRulesApi.getRules({
      type: businessRulesApi.RULE_TYPES.agent,
    })

    commit(mutationTypes.SET_AGENT_RULE, rule)
  },
  async updateAgentRule({ dispatch, commit }, { agentRule, directorRules }) {
    commit(mutationTypes.UPDATING_AGENT_RULE_PENDING)
    try {
      await businessRulesApi.updateAgentRule(agentRule, directorRules)
      commit(mutationTypes.UPDATING_AGENT_RULE_SUCCESS)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Rules successfully reordered.' },
        { root: true }
      )
    } catch (error) {
      commit(mutationTypes.UPDATING_AGENT_RULE_ERROR)
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },
  async getDirectorRules({ commit, state }) {
    const rules = await businessRulesApi.getRules({
      type: businessRulesApi.RULE_TYPES.director,
      agentId: state.dedupeAgentId,
    })

    commit(mutationTypes.SET_DIRECTOR_RULES, rules)
  },
  async getSearchDirectorRules({ commit, state }) {
    const rules = await businessRulesApi.getRules({
      type: businessRulesApi.RULE_TYPES.director,
      agentId: state.searchAgentId,
    })

    commit(mutationTypes.SET_DIRECTOR_RULES, rules)
  },
  async activateDirectorRule(
    { commit, dispatch },
    { id, confirmed, setMiniStepper }
  ) {
    try {
      const data = await businessRulesApi.activateDirectorRule(id, {
        confirmed,
        setMiniStepper,
      })

      if (data.needsConfirmation) {
        return data
      } else {
        commit(mutationTypes.SET_DIRECTOR_RULES, data)

        dispatch(
          'app/showSuccessNotification',
          {
            message: 'Rule successfully enabled.',
          },
          { root: true }
        )

        return {
          needsConfirmation: false,
        }
      }
    } catch (error) {
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },
  async deactivateDirectorRule({ commit, dispatch }, { id, confirmed }) {
    try {
      const data = await businessRulesApi.deactivateDirectorRule(id, confirmed)

      if (data.needsConfirmation) {
        return data
      } else {
        commit(mutationTypes.SET_DIRECTOR_RULES, data)

        dispatch(
          'app/showSuccessNotification',
          {
            message: 'Rule successfully disabled.',
          },
          { root: true }
        )

        return {
          needsConfirmation: false,
        }
      }
    } catch (error) {
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },
  async getPresentationRules({ commit }) {
    const rules = await businessRulesApi.getRules({
      type: businessRulesApi.RULE_TYPES.presentation,
    })

    commit(mutationTypes.SET_PRESENTATION_RULES, rules)
  },
  async updatePresentationRule({ commit, dispatch }, payload) {
    try {
      const rule = await businessRulesApi.updatePresentationRule(payload)
      commit(mutationTypes.UPDATING_PRESENTATION_SUCCESS, rule)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Layout successfully updated.' },
        { root: true }
      )
    } catch (error) {
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },

  async createPresentationRule({ commit, dispatch }, payload) {
    try {
      const rule = await businessRulesApi.createPresentationRule(payload)
      commit(mutationTypes.CREATING_PRESENTATION_SUCCESS, rule)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Layout successfully created.' },
        { root: true }
      )
    } catch (error) {
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },
  async deletePresentationRule({ commit, dispatch }, id) {
    commit(mutationTypes.DELETING_PRESENTATION_PENDING, id)

    try {
      await businessRulesApi.deleteRule(id)
      commit(mutationTypes.DELETING_PRESENTATION_SUCCESS, id)
      dispatch(
        'app/showSuccessNotification',
        { message: 'Layout successfully deleted.' },
        { root: true }
      )
    } catch (error) {
      commit(mutationTypes.DELETING_PRESENTATION_ERROR, id)
      dispatch(
        'app/showErrorNotification',
        { message: 'Something went wrong. Please try again later!' },
        { root: true }
      )
      throw error
    }
  },
  async getDirectorRule({ commit, dispatch }, id) {
    try {
      const rule = await businessRulesApi.getRule(id)

      commit(mutationTypes.SET_DIRECTOR_RULE, rule)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getRuleEntityMetadata({ commit }, { ruleType, multiMapId, entities }) {
    const metadataByEntity = await businessRulesApi.getRuleEntityMetadata({
      multiMapId,
      ruleType,
      entities,
    })

    commit(mutationTypes.SET_RULES_METADATA, { ruleType, metadataByEntity })
  },
  async getRule({ commit }, id) {
    const rule = await businessRulesApi.getRule(id)
    commit(mutationTypes.SET_RULE, rule)
  },
  async createSegmentationRule(
    { commit, dispatch },
    { directorRuleId, rule, segmentations }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createSegmentationRule(
        directorRuleId,
        rule,
        segmentations
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateSegmentationRule({ commit, dispatch }, { rule, segmentations }) {
    try {
      const ruleUpdated = await businessRulesApi.updateSegmentationRule(
        rule,
        segmentations
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async createSearchRule(
    { commit, dispatch },
    { directorRuleId, rule, fields, actions }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createSearchRule(
        directorRuleId,
        rule,
        fields,
        actions
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateSearchRule({ commit, dispatch }, { rule, fields, actions }) {
    try {
      const ruleUpdated = await businessRulesApi.updateSearchRule(
        rule,
        fields,
        actions
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async createMatchCriteriaRule(
    { commit, dispatch },
    { directorRuleId, rule, criterias, crossObject }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createMatchCriteriaRule(
        directorRuleId,
        rule,
        criterias,
        crossObject
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateMatchCriteriaRule(
    { commit, dispatch },
    { rule, criterias, crossObject }
  ) {
    try {
      const ruleUpdated = await businessRulesApi.updateMatchCriteriaRule(
        rule,
        criterias,
        crossObject
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async createAssignmentRule(
    { commit, dispatch },
    { directorRuleId, rule, assignments }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createAssignmentRule(
        directorRuleId,
        rule,
        assignments
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateAssignmentRule({ commit, dispatch }, { rule, assignments }) {
    try {
      const ruleUpdated = await businessRulesApi.updateAssignmentRule(
        rule,
        assignments
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async createPrimaryRule(
    { commit, dispatch },
    { directorRuleId, rule, conditions }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createPrimaryRule(
        directorRuleId,
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updatePrimaryRule({ commit, dispatch }, { rule, conditions }) {
    try {
      const ruleUpdated = await businessRulesApi.updatePrimaryRule(
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async createRollUpRule(
    { commit, dispatch },
    { directorRuleId, rule, conditions }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createRollUpRule(
        directorRuleId,
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateRollUpRule({ commit, dispatch }, { rule, conditions }) {
    try {
      const ruleUpdated = await businessRulesApi.updateRollUpRule(
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async createWriteRule(
    { commit, dispatch },
    { directorRuleId, rule, conditions }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createWriteRule(
        directorRuleId,
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateWriteRule({ commit, dispatch }, { rule, conditions }) {
    try {
      const ruleUpdated = await businessRulesApi.updateWriteRule(
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async setPresentationRule({ commit, dispatch }, directorRule) {
    try {
      await businessRulesApi.updateDirectorRule(directorRule)

      commit(mutationTypes.SET_DIRECTOR_RULE, directorRule)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  getOperators: async ({ commit, dispatch }) => {
    try {
      const data = await businessRulesMockApi.getOperators()

      commit(mutationTypes.SET_OPERATORS, data)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  getConvertLeadStatuses: async ({ commit, dispatch }) => {
    try {
      const data = await businessRulesMockApi.getConvertLeadStatuses()

      commit(mutationTypes.SET_CONVERT_LEAD_STATUSES, data)

      return data
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
}
